import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {SectionHeader} from '../Landing/SectionHeader';
import {navigate} from 'gatsby';
import {RectButton} from '../../Components/RectButton';
import {Card, FlexColumnCenterCenter, FlexRowCenterBetween} from '../../widget';
import {Color} from '../../constants';
import {ChevronRight} from '@styled-icons/material';
import logo from '../../../static/images/app_attlogo3.png';

const ProfileListItem = (props) => {
  const {item} = props;
  return (
    <StyledProfileListItem onClick={item.onClick}>
      <div className="title">{item.title}</div>
      <ChevronRight size={18} color={Color.black_50} />
    </StyledProfileListItem>
  );
};
const StyledProfileListItem = styled.button`
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 8px;
  text-align: left;
  ${FlexRowCenterBetween};
  border: none;
  border-bottom: 1px solid ${Color.black_30};
  outline: none;
  background-color: ${Color.white};
  & :active {
    opacity: 0.3;
  }
`;

function ProfilePage(props) {
  const [user] = useOutlet('user');

  const [actions] = useOutlet('actions');

  const profileList = [
    {
      title: '會員資訊  ',
      onClick: async () => {
        await navigate('/user-profile');
      },
    },
    {
      title: '常見會員問題',
      onClick: async () => {
        await navigate('/common-questions');
      },
    },
  ];

  return (
    <Wrapper>
      {user && (
        <div className="view">
          <img className="circle image" src={logo} alt="logo" />
          <Card style={{width: '100%'}}>
            {profileList.map((item, index) => (
              <ProfileListItem key={index} item={item} />
            ))}
          </Card>
          <div style={{flex: 1}} />
          <RectButton
            onClick={() => {
              actions.logout();
            }}
            style={{width: '100%'}}>
            登出
          </RectButton>
        </div>
      )}
      {!user && (
        <div className="view">
          <div style={{flex: 1}} />
          <SectionHeader
            style={{marginBottom: 60}}
            align="center"
            title="登入 / 註冊"
            caption="login / register"
          />

          <RectButton
            onClick={async () => {
              await navigate(`/login`);
            }}
            style={{width: '100%', marginBottom: 10}}>
            會員登入
          </RectButton>
          <RectButton
            onClick={async () => {
              await navigate(`/register`);
            }}
            style={{
              width: '100%',
              backgroundColor: Color.black_10,
              color: Color.black,
            }}>
            註冊會員
          </RectButton>

          <div style={{flex: 1}} />

          <button
            className="forgot-password"
            style={{marginBottom: 5}}
            onClick={async () => {
              await navigate('/forgot-password');
            }}>
            忘記密碼
            <ChevronRight />
          </button>
          <button
            className="common-question"
            onClick={async () => {
              await navigate('/common-questions');
            }}>
            常見問題
            <ChevronRight />
          </button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
  & > .view {
    flex: 1;
    ${FlexColumnCenterCenter};
    & > .circle.image {
      width: 60px;
      height: 60px;
      background-color: ${Color.orange};
      border-radius: 100%;
      margin: 20px 0;
    }
    & > button.common-question,
    button.forgot-password {
      position: relative;
      padding: 8px 28px 8px 15px;
      font-size: 12px;
      line-height: 1.57;
      border: none;
      background-color: ${Color.black_10};
      border-radius: 10px;
      cursor: pointer;
      & > svg {
        position: absolute;
        width: 24px;
        height: 24px;
        fill: ${Color.orange};
        font-weight: bold;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: ${Color.black_20};
      }
    }
  }
`;

export default ProfilePage;
